<template>
  <div
    class="components_task_side_bar_teach_pages_make_titel_page bbox d-flex align-start justify-space-between"
  >
    <div class="title">教学内容{{edit?'制作':''}}</div>
    <!-- <div class="status_group">

    </div> -->
    <div class="fun_group d-flex align-center"
      v-if="edit"
    >
      <div class="menu cp"
        @click="doReturn();"
      ></div>
      <div class="close cp"
        @click="doClose();"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    doReturn(){
      this.$emit('handleChangePage', 'price')
    },
    doClose(){
      this.$emit('handleChangePage', 'start')
    },
  }
}
</script>


<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_titel_page{
  width: 100%;
  .title{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
  .fun_group{
    height: 30px;
    background: #F4F4F4;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 4px;
    .menu{
      @include background(18px, 4px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/teach/menu.png);
      margin: {
        left: 6px;
        right: 16px;
      }
      position: relative;
      &::after{
        content: '';
        display: block;
        position: absolute;
        height: 14px;
        width: 1px;
        background-color: #d8d8d8;
        top:50%;
        right: -11px;
        transform: translateY(-50%);
      }
    }
    .close{
      @include background(10px, 10px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/teach/close.png);
    }
  }
}
</style>