<template>
  <div 
    class="components_task_main_linebar_task_user_page bbox d-flex align-center flex-wrap align-content-center"
    ref="page"
    id="components_task_main_linebar_task_user_page"
    v-resize="handleResize"
  >
    <div class="icon flex-shrink-0"></div>
    <div class="list flex-shrink-0 d-flex align-center">
      <template
        v-for="(item, index) in list"
      >
        <div class="avatar_group"
          :key="index"
          v-if="index<max_avatar_number"
          @click="openUserDetail(item);"
        >
          <img 
            :src="`${$avatar_url}${item.thumbnail}@!small200`"
            alt=""
            class="avatar"
          >
          <div
            class="readed_group d-flex align-center justify-center"
            v-if="item.isread"
          >
            <div class="text">已读</div>
          </div>
          <div class="hint">{{priorityName(item)}}</div>
        </div>
      </template>
    </div>
    <div class="more flex-shrink-0"
      @click="checkMore();"
      v-if="is_more"
    ></div>
    <div class="add flex-shrink-0"
      v-if="status_add"
      @click="startSelectTaskUser();"
    >
      <div class="hint">点击添加参与人</div>
    </div>
    <select-users
      :select_all="select_all"
      :list="select_list"
      v-if="switch_select_users"
      @handleClose="closeSelectTaskUser"
      @handleSure="changeTaskUser"
    ></select-users>
    <select-target
      v-if="switch_select_target"
      :select_target="select_target"
      :select_target_chat_id="select_target_chat_id"
      :select_target_chat_type="select_target_chat_type"
      :list="list"
      @handleClose="closeSelectTarget"
      @handleSure="changeSelectTarget"
    ></select-target>
    <net-user-list
      v-if="switch_net_user_list"
      title="查看参与人"
      :list="list"
      :open_readed="true"
      :open_add="editable"
      :loading_users="loading_users"
      @handleTouchAdd="startSelectTaskUser"
      @handleClose="closeNetUserList"
    ></net-user-list>
    <select-contacts
      v-if="switch_select_contacts"
      :title="select_contacts_title"
      :select_target="select_target"
      :chat_id="Number(select_contacts_data.chat_id)"
      :chat_type="Number(select_contacts_data.chat_type)"
      @handleClose="closeSelectContacts();"
    ></select-contacts>
  </div>
</template>
<script>
import tools from '@/api/tools'
import global from '@/api/global'
import SelectUsers from '@/components/PopUp/SelectUsers.vue';
import SelectTarget from '@/components/PopUp/SelectTarget.vue';
import NetUserList from '@/components/PopUp/NetUserList.vue';
import SelectContacts from '@/components/PopUp/SelectContacts/index.vue';
export default {
  components: {
    SelectUsers,
    SelectTarget,
    NetUserList,
    SelectContacts,
  },
  props:{
    select_target: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    view: {
      type: Boolean,
      default: true,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      observer: null,
      icon: 40, // 24: width, 16: margin-right
      avatar: 44, // 34: width, 10: margin-right
      more: 30, // 20: width, 10: margin-right
      add: 66, // 66: width, 54: 额外
      padding: 40, // 20: padding-left, 20: padding-right
      client_width: 0,

      select_list: [],
      select_all: false,
      switch_select_users: false,

      switch_select_target: false,

      switch_net_user_list: false,

      switch_select_contacts: false,
      select_contacts_title: '选择组内联系人',
      select_contacts_data: {},

      pn: 1,
      loading_users: false,
    }
  },
  computed:{
    max_avatar_number(){
      const {client_width, icon, avatar, more, add, padding, view } = this;
      // 预览模式 只显示一个人
      if(view){
        return 1;
      }
      if(client_width){
        const spare_width = client_width - icon - more - add - padding;
        const number = Math.floor(spare_width / avatar);
        return number;
      }
      return 10;
    },
    is_more(){
      const {max_avatar_number, list, view} = this;
      if(view){
        return false;
      }
      if(list && list.length){
        return list.length > max_avatar_number;
      }
      return false;
    },
    task(){
      return this.$store.state.task;
    },
    task_config(){
      return this.$store.state.task_config;
    },
    list(){
      const { task, task_config } = this
      const { readtaskUsers, taskUsers } = task
      const { users } = task_config
      let list = []
      if (users && users.length) {
        list = users
      } else {
        list = task.taskUsers
      }
      return list
    },
    status_add(){
      const {call, draft, chat_type, select_target_chat_type} = this.$store.state.task_config;
      const {task, select_target, editable, view, recycle} = this;
      // // console.log({task, select_target, editable, view, draft, chat_type, select_target_chat_type})
      if(recycle){
        return false;
      }
      if(draft){
        return true;
      }
      if(view){
        return false;
      }
      if(task.task.taskType == 2){
        return true;
      }
      if(Number(chat_type) === 0 && !select_target){
        return false;
      }
      if(editable){
        return true;
      }
      if(call){
        return true;
      }
      return false;
    },
    select_target_chat_id(){
      return this.$store.state.task_config.select_target_chat_id;
    },
    select_target_chat_type(){
      return this.$store.state.task_config.select_target_chat_type;
    },
    
  },
  // watch: {
  //   'task_config.users': {
  //     handler: function(){},
  //     deep: true,
  //   },
  // },
  methods:{
    handleResize(data){
      const {width} = data;
      const client_width  = Math.floor(parseFloat(width));
      if(client_width){
        this.client_width = client_width;
      }
    },
    priorityName(item){
      const {updateremark, friendRemark, userName, chatUserName} = item;
      if(updateremark === 1){
        return friendRemark || chatUserName || userName;
      }
      return chatUserName || userName;

    },
    closeSelectTaskUser(){
      this.switch_select_users = false;
    },
    closeSelectContacts(){
      this.switch_select_contacts = false;
    },
    async startSelectTaskUser(){
      const {select_target, select_target_chat_id, select_target_chat_type} = this;
      // console.log(select_target)
      if(select_target){
        //#需要选择工作组
        this.switch_select_target = true;

      }else{
        //# 不需要选择工作组
        const {task} = this
        const {chatId, chatType, draft, tousers, add_users, delete_users, id} = task.task 
        let chat_id = 0
        let chat_type = 0
        if (this.$route.params.id) {
          chat_id = global.decodeBase64(this.$route.params.id)
          chat_type = tools.getChatType()
          if (Number(chat_id) !== Number(chatId)) {
            chat_id = chatId
            chat_type = chatType
          }
        }else{
          chat_id = chatId
          chat_type = chatType
        }
        console.log(chat_id, chat_type)
        if(draft === 1){
          chat_id = global.decodeBase64(this.$route.params.id)
          chat_type = tools.getChatType()
        }
        let res = await this.$api.dialog.getDialogUsers({chat_id, chat_type, loading: true})
        // console.log(res)
        // let select_list = this.$tools.deepClone(this.$store.state.dialog_user_list);
        if(res === false){
          return
        }
        let select_list = this.$tools.deepClone(res.list)
        const {list} = this;
          //## 自己不能选
          //## 群主除了自己都可以选择 群主有全选功能
          //## 不是群主只能加人 不能减自己以外
        const userid = this.$tools.getUserId()
        const group_owner_id = this.task.task.userId || userid;
        const is_group_owner = userid == group_owner_id;
        if(is_group_owner){
          //### 自己是群主
          this.select_all = true;
        }
        // 获取所有已选择
        console.log(task)
        console.log(tousers, add_users, delete_users)
        let tousers_arr = []
        let list_arr = []
        if(tousers){
          list_arr = tousers.split(',')
          tousers_arr = tousers.split(',')
        }
        // console.log(list_arr)
        if(add_users){
          list_arr = [
            ...new Set([
              ...list_arr,
              ...add_users.split(',')
            ])
            
          ]
        }

        // console.log(list_arr)
        if(delete_users){
          let delete_users_arr = delete_users.split(',')
          if(list_arr && list_arr.filter){

            list_arr = list_arr.filter( item=>{
              const is_in_delete = delete_users_arr.find(unit=>{
                return unit === item
              })
              return !is_in_delete
            })
          }
        }
        select_list = select_list.map(item=> {
          if(item.userId == group_owner_id){
            item.hide = true;
          }
          if(is_group_owner){
            item.deletable = true;
          }else{
            if(item.userId == userid){
              item.deletable = true;
            }

            // 不在tousers中可以删除
            let is_in_tousers_arr = tousers_arr.find(unit=> {
              return unit == item.userId
            })
            if(!is_in_tousers_arr){
              item.deletable = true
            }
          }
          

          
            console.log(list_arr)

          //## 是否已选择
          if(id){
            if(list_arr && list_arr.find){

              const find = list_arr.find(unit=>{
                return unit == item.userId;
              })
              if(find !== undefined){
                item.selected = true;
              }
            }
          }else{
            const find = list.find( unit => {
              return unit.userId == item.userId
            })
            if(find){
              item.selected = true
            }
          }
          return item;

        })
        // console.log(select_list,list)
        this.select_list = select_list;
        this.switch_select_users = true;
      }
      
    },
    changeTaskUser(selected_list){
      // 将接收到的数组与当前第一个拼接
      const {task, task_config} = this;
      const {taskUsers} = task;
      const {users} = task_config
      const { id, tousers} = task.task

      let add_users = ''
      let delete_users = ''
      let list = [];
      list.push(users[0])
      // console.log(selected_list)

      if(selected_list && selected_list.length){
        selected_list.forEach(item=> {
          const is_in_users = users.find(unit=>{
            return unit.userId == item.userId
          })
          if(is_in_users){
            list.push(is_in_users)
          }else{
            list.push(item)
          }
        })
      }
      // list = list.concat(selected_list);

      // 优先使用users原有的数据
      this.$set(this.task_config, 'users', list)
      // this.$store.commit('set_task_config', Object.assign(
      //   {},
      //   this.$store.state.task_config,
      //   {users: list}
      // ))
      if(id){
        const touser_arr = tousers.split(',')

        // 获取增加的add_users
        list.forEach(item => {
          if(!touser_arr.includes(String(item.userId))){
            add_users += `${add_users && ','}${item.userId}`
          }
        })
        // console.log({add_users})

        // delete_users
        touser_arr.forEach(item => {
          let is_in = list.find(unit => {
            return unit.userId === Number(item)
          })
          if(is_in === undefined){
            delete_users += `${delete_users && ','}${item}`
          }
        })

        // console.log({delete_users})

        task.task.add_users = add_users
        task.task.delete_users = delete_users
      }

      //关闭
      this.closeSelectTaskUser();
    },
    closeSelectTarget(){
      this.switch_select_target = false;
    },
    changeSelectTarget(data){
      const {list, chat_type, chat_id, from} = data;
      if(chat_type, chat_id){

        const {task} = this;
        const {taskUsers} = task;
        let users = [];
        users.push(taskUsers[0])
        users = users.concat(list);
        this.task_config.users = users
        this.task_config.select_target_chat_type = chat_type
        this.task_config.select_target_chat_id = chat_id
        this.task_config.from = from

        // this.$store.commit('set_task_config', Object.assign(
        //   {},
        //   this.$store.state.task_config,
        //   {
        //     users,
        //     select_target_chat_id: chat_id,
        //     select_target_chat_type: chat_type,
        //     from,  
        //   }
        // ))
      }
      //关闭
      this.closeSelectTaskUser();
    },
    checkMore(){
      this.switch_net_user_list = true;
    },
    closeNetUserList(){
      this.switch_net_user_list = false;
    },
    openUserDetail(item){
      this.$api.user.getInfo(item);
      // this.$user_detail({data: item});
      // // console.log(item);
    },
    async init(){
      // this.pn = 1
      const {list,task} = this
      const {id} = task.task
      // if(id && list && list.length == 20){
      if(id){
        setTimeout(() => {
          this.getUserList()
        }, 200);
      }
    },
    async getUserList(){
      this.loading_users = true
      // this.pn += 1 
      const {list, pn, task, task_config} = this
      const {users} = task_config
      const {id:taskId, userId} = task.task
      const res = await global.doPost({
        // url: '/projectTask/queryTaskUserkListByPage',
        url: '/projectTask/queryTaskUserkList',
        data: {
          taskId,
          // pn,
        }
      })
      if(res.message === 'success'){
        const {contents} = res
        const {taskUsers} = contents
        // console.log(taskUsers, users)
        // console.log(taskUsers , taskUsers.length, Boolean(taskUsers && taskUsers.length))
        // console.log(users, users.length, Boolean(users && users.length))
        if(taskUsers && taskUsers.length){
          if(users && users.length){
            // console.log(users.concat(taskUsers))
            // task_config.users = users.concat(taskUsers)
            // this.$set(task_config, 'users', users.concat(taskUsers))
            // 将创建者移到第一位
            const index = taskUsers.findIndex(item=> {
              return item.userId == userId
            })
            if(index !== -1){
              const create_user = taskUsers[index]
              console.log(index, create_user)
              taskUsers.splice(index,1)
              taskUsers.unshift(create_user)
            }

            this.$set(task_config, 'users', taskUsers)
            

          }
        }
        // this.$nextTick(function(){
          
        //   // console.log(task_config.users)
        // })
        // if(taskUsers.length == 20){
        //   await this.getUserList()
        // }else{
          this.loading_users = false
        // }
      }else{
        this.loading_users = false
      }

      // console.log(task_config)

    },
  },
  mounted(){
    // // console.log(this.$refs.page.clientWidth)
    this.client_width = this.$refs.page.clientWidth;
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.components_task_main_linebar_task_user_page{
  width: 100%;
  background-color: #fff;
  .avatar_group{
    position: relative;
    z-index: 2;
    margin-right: 10px;
    &:hover{
      .hint{
        opacity: 1;
        transform: translateX(-50%) scaleY(100%);
      }
    }
    .avatar{
      width: 34px;
      height: 34px;
      border-radius: 4px;
      overflow: hidden;
      border:none;
      display: block;
    }
    .readed_group{
      font-size: 12px;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      line-height: 14px;
      border-radius: 0 0 4px 4px;
      background-color: rgba(0,0,0,0.5);
      color:#fff;
      .text{
        transform: scale(0.6);
      }
    }
    .hint{
      transition-delay: 0.5s;
      left: 50%;
      transform-origin: top center;
      transform: translateX(-50%) scaleY(0);
      top: 40px;
      &::before{
        border-bottom: 4px solid #232D37;
        left: 50%;
        transform: translateX(-50%);
        top:-8px;
      }
    }
  }
  .hint{
    @include transition;
    opacity: 0;
    position: absolute;
    z-index: 3;
    background-color: #232D37;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    word-break: keep-all;
    white-space: nowrap;
    &::before{
      content: '';
      display: block;
      position: absolute;
      z-index: 4;
      width:0;
      height: 0;
      border: 4px solid transparent;
    }
  }
  .icon{
    @include background;
    width:24px;
    height: 24px;
    background-image: url(~@/assets/images/task/task_user_icon.png);
    margin-right: 16px;
  }
  .more{
    @include background(20px, 5px);
    width: 20px;
    height: 5px;
    background-image: url(~@/assets/images/task/menu.png);
    margin-right: 10px;
  }
  .add{
    @include background;
    position: relative;
    z-index: 2;
    width: 66px;
    height: 34px;
    background-image: url(~@/assets/images/task/add_task_user.png);
    &:hover{
      .hint{
        opacity: 1;
      }
    }
    .hint{
      left: 74px;
      top:50%;
      transform: translateY(-50%);
      &::before{
        border-right: 4px solid #232D37;
        top:50%;
        transform: translateY(-50%);
        left:-8px;
      }
    }
  }
}
</style>