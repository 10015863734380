var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_titel_page bbox d-flex align-start justify-space-between",
    },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("教学内容" + _vm._s(_vm.edit ? "制作" : "")),
      ]),
      _vm.edit
        ? _c("div", { staticClass: "fun_group d-flex align-center" }, [
            _c("div", {
              staticClass: "menu cp",
              on: {
                click: function ($event) {
                  return _vm.doReturn()
                },
              },
            }),
            _c("div", {
              staticClass: "close cp",
              on: {
                click: function ($event) {
                  return _vm.doClose()
                },
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }