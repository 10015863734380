var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_price_page bbox d-flex align-start",
    },
    [
      _c("div", { staticClass: "d-flex flex-column align-center wrapper" }, [
        _c("div", { staticClass: "title align-self-start" }, [
          _vm._v("销售价格设置"),
        ]),
        _c(
          "div",
          { staticClass: "price_group d-flex align-start flex-column" },
          [
            _c("div", { staticClass: "key flex-shrink-0 mb-10" }, [
              _vm._v("设置价格："),
            ]),
            _c("div", { staticClass: "price_box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.price,
                    expression: "price",
                  },
                ],
                ref: "input",
                staticClass: "price bbox",
                attrs: { type: "text" },
                domProps: { value: _vm.price },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.price = $event.target.value
                  },
                },
              }),
              _c("div", { staticClass: "symbol" }, [_vm._v("¥")]),
              _c("div", { staticClass: "hint", class: { error: _vm.error } }, [
                _vm._v(_vm._s(_vm.hint)),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "intro_group d-flex align-start flex-column mb-20" },
          [
            _c("div", { staticClass: "name flex-shrink-0 mb-10" }, [
              _vm._v("设置简介:"),
            ]),
            _c("el-input", {
              staticClass: "intro",
              attrs: {
                type: "textarea",
                autosize: { minRows: 5, maxRows: 5 },
                placeholder: "输入简介内容",
                resize: "none",
              },
              model: {
                value: _vm.intro,
                callback: function ($$v) {
                  _vm.intro = $$v
                },
                expression: "intro",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "notice" }, [
          _vm._v(
            "*设置、更改收益并上传教学文件后，系统将进行内容审核，审核通过后将自动发布，审核周期一般为1-2个工作日，请关注并耐心等待。"
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "fun_group d-flex align-center justify-space-between",
          },
          [
            _c(
              "div",
              {
                staticClass: "button btn_dark",
                class: { disabled: !_vm.can_save },
                on: {
                  click: function ($event) {
                    return _vm.doSave()
                  },
                },
              },
              [_vm._v("保存")]
            ),
            _c(
              "div",
              {
                staticClass: "button btn_light",
                on: {
                  click: function ($event) {
                    return _vm.doCancel()
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }