<template>
  <div
    class="components_task_main_page bbox d-flex flex-column"
  >
    <task-user
      class="flex-shrink-0 task_user_component px-20 border-bottom"
      :editable="editable"
      :select_target="select_target"
      :view="view"
      :recycle="recycle"
      v-if="task_user_status"
    ></task-user>
    <task-title
      class="flex-shrink-0 task_title_component px-20 border-bottom"
      :editable="editable"
      v-if="task_title_status"
    ></task-title>
    <task-tag
      class="flex-shrink-0 task_tag_component px-20 border-bottom"
      :tags="task.task.tags || null"
      :editable="editable"
      v-if="tag_status && !task_config.hide_tag"
    ></task-tag>
    <widget-container
      :list="taskDeatails"
      class="widge_container_component"
      :editable="editable"
      :sell="sell"
      :public_notice="public_notice"
      :shopping_mail="shopping_mail"
      @handleDeleteWidget="deleteWidget"
      @handleDeleteRelevancy="deleteRelevancy"
      @handleMofifiedSign="modifiedSign"
      @handleSortFile="handleSortFile"
      ref="widget_container"
      :recycle="recycle"
      :system="system"
    >
      <template v-slot>
        <div
          class="call_task_entrance d-flex flex-column align-center justify-center cp bbox"
          @click="startCallTask();"
          v-if="call_hint_status"
        >
          <img src="~@/assets/images/task/call_task_entrance.png" alt="" class="un_sel">
          <div class="text un_sel">调用已有模板 创建全新任务</div>
        </div>
      </template>
    </widget-container>

    <call-task-pop-up
      v-if="switch_call_task"
      @handleClose="closeCallTask"
      @handleCall="callTask"
    ></call-task-pop-up>
  </div>
</template>
<script>
import TaskUser from './Main/LineBar/TaskUser.vue';
import TaskTitle from './Main/LineBar/TaskTitle.vue';
import TaskTag from './Main/LineBar/TaskTag.vue';
import WidgetContainer from './Main/WidgetContainer.vue';
import CallTaskPopUp from '@/components/PopUp/CallTask.vue';
export default {
  components: {
    TaskUser,
    TaskTitle,
    TaskTag,
    WidgetContainer,
    CallTaskPopUp,
  },
  provide: {
    printMode: false
  },
  props:{
    select_target: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    view: {
      type: Boolean,
      default: true,
    },
    public_notice: {
      type: Boolean,
      default: false,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      switch_call_task: false,
    }
  },
  computed: {
    task(){
      // // console.log( this.$store.state.task)
      return this.$store.state.task;
    },
    task_config(){
      return this.$store.state.task_config
    },
    taskDeatails(){
      const { task } = this
      const { taskDeatails } = task
      return taskDeatails
    },
    // editable(){
    //   const {task_config} = this.$store.state;
    //   const {editable} = task_config;
    //   // console.log(editable)
    //   return editable;
    // }
    tag_status(){
      const {editable, public_notice, system} = this;
      const {call, draft} = this.$store.state.task_config;
      if(system){
        return false;
      }
      if(draft){
        return false;
      }
      if(public_notice){
        return false;
      }
      if(call){
        return true;
      }
      if(editable){
        return true;
      }
      return false;
    },
    task_user_status(){
      const {public_notice, system, sell} = this;
      if(sell){
        return false;
      }
      if(system){
        return false;
      }
      if(public_notice){
        return false;
      }
      return true;
    },
    task_title_status(){
      const {public_notice} = this;
      if(public_notice){
        return false;
      }
      return true;
    },
    call_hint_status(){
      const {task_config} = this.$store.state;
      const {create} = task_config;
      if(create){
        return true;
      }
      return false;
    }
  },
  methods: {
    startCallTask(){
      this.switch_call_task = true;
    },
    closeCallTask(){
      this.switch_call_task = false;
    },
    deleteWidget (params) {
      const { widget, parent } = params
      const { task_config } = this.$store.state
      const { call, chat_id, view, reEditTeach } = task_config
      const { id, widget_sort } = widget
      const { taskDeatails, public_notice } = this
      let url = '/projectTask/deleteControl'
      if (public_notice) {
        url = '/projectChat/deleteNoticeControl';
      }
      /**
       * 查询控件是否有投屏任务
       */
      let desc = '';
      const projection_screen_nos = this.checkHasProjectionScreen(widget);
      if(projection_screen_nos !== false){
        desc = '任务正在投屏中，删除任务将结束投屏'
      }
      // // console.log(widget)
      // return
      this.$openConfirm({
        title:'确认删除控件?',
        desc,
        sure:'是',
        cancel:'否',
        onSure: async () => {
          if(projection_screen_nos){
            this.$global.doPost({
              url: '/screen/endShowTaskByUser',
              data: {
                screenNos: projection_screen_nos,
              }
            })
          }

          if (id && !call && (!view || reEditTeach)) {
            const res = await this.$global.doPost({
              url,
              data: {
                id, 
                chatId: this.$api.moduleTask.sendTaskFun.generateChatId()},
            })
            if(!public_notice){
              // 范文删除时没有taskLog
              if(res.contents && res.contents.taskLog){
                // await this.pushMessageList(res.contents.taskLog);
                this.$api.moduleTask.common.pushMessageList(res.contents.taskLog);
              }
              if(res.message !== 'success'){
                return;
              }
            }else{
              // 公示板单独处理
            }
          }
          // 调用 || 没有id的控件 直接删除
          if (parent === null) {
            const index = taskDeatails.findIndex(unit=>{
              if (id) {
                return unit.id == id
              } else {
                return unit.widget_sort == widget_sort
              }
            })
            if (index != -1) {
              taskDeatails.splice(index, 1)
            }
          } else {
            const index = parent.controlLinkList.findIndex((unit) => {
              if (id) {
                return unit.id == id
              } else {
                return unit.widget_sort == widget_sort
              }
            })
            if (index !== -1) {
              parent.controlLinkList.splice(index, 1)
            }
          }
        }
      })
      
    },
    deleteRelevancy(data){
      const {task_config} = this.$store.state;
      const {call, chat_type, chat_id, view} = task_config;
      const {id, controlLinkList} = data;
      this.$openConfirm({
        title:'确定要删除关联区?',
        desc:'该操作不可撤销',
        sure:'是',
        cancel:'否',
        onSure:async ()=>{
          if(id && !call && !view){
            // 判断关联是否有id
            const link_has_id = controlLinkList.findIndex((unit=>{
              return unit.id;
            }))
            if(link_has_id != -1){
              const res = await this.$global.doPost({
                url: '/projectTask/deleteLinkarea',
                data: {
                  id,
                  chatType: chat_type,
                  chatId: chat_id,
                },
                openNoitce: false,
              })
          
              if(res.contents.taskLog){
                // 追加消息列表
                // this.$parent.pushMessageList(res.contents.taskLog);
                this.$api.moduleTask.common.pushMessageList(res.contents.taskLog);
              }
            }
            // 清空
            // if(res && res.message == 'success'){
              
            // }
          }
          this.$set(data,'islink', 0);
          this.$set(data,'controlLinkList', []);
        
        },
      })
      return;
    },
    addNewWidget(){
      this.$refs.widget_container.addNewWidget();
    },
    switchSidebar(){
      this.$refs.widget_container.switchSidebar();
    },
    callTask(params){
      // const { taskId, config } = params
      this.$emit('handleCall', params);
    },
    modifiedSign(params){
      const {item, parent} = params;
      if(parent){
        this.$set(parent, 'modified', true);
        parent.modified = true;
      }
      this.$set(item, 'modified', true);
      item.modified = true;
      // console.log(parent, item)
    },
    handleSortFile(params){
      this.$emit('handleSortFile', params);
    },
    /**
     * 检测是否含有正在投屏的控件(包含关联内数据)
     * @return false || 正在投屏的控件id
     */
    checkHasProjectionScreen(widget){
      const {task_config} = this.$store.state;
      const {call} = task_config;
      if(call){
        return false;
      }
      const {type,id, screenList, screens, controlLinkList} = widget;
      // let screen_widget_ids = '';
      let screen_list = [];
      if(type == 11){
        if(screenList && screenList.length){
          const has = screenList.find(unit=>{
            return unit.useflag === 1 && (unit.screenTask.taskDetailId === id); 
          })
          if(has){
            // screen_widget_ids += `${screen_widget_ids ? ',': ''}${id}`;
            const arr = screens.split(',');
            screen_list = [
              ...new Set([
                ...screen_list,
                ...arr,
              ])
            ]
          }
        }
      }
      /**
       * 查询关联是否有正在投屏的控件
       */
      if(controlLinkList && controlLinkList.length){
        for(const link of controlLinkList){
          const {
            type: link_type,
            id: link_id,
            screenList: link_screen_list,
            screens: link_screens,
          } = link;
          if(link_type == 11){
            if(link_screen_list && link_screen_list.length){
              const has = link_screen_list.find(unit=>{
                return unit.useflag === 1 && (unit.screenTask.taskDetailId === link_id); 
              })
              if(has){
                // screen_widget_ids += `${screen_widget_ids ? ',': ''}${link_id}`;
                const arr = link_screens.split(',');
                screen_list = [
                  ...new Set([
                    ...screen_list,
                    ...arr,
                  ])
                ]
              }
            }
          }
        }
      }

      // if(screen_widget_ids !== ''){
        // return screen_widget_ids;
      // }
      if(screen_list && screen_list.length){
        return screen_list.join(',')
      }
      return false;


    }




  },
  mounted(){
    // // console.log('chat_detail');
    const {state} = this.$store;
    const {task_config} = state;
    const {open_with_call_page} = task_config;
    if(open_with_call_page){
      this.switch_call_task = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_page{
  width: 100%;
  height: 100%;
  overflow: auto;
  .px-20{
    padding-left: 20px;
    padding-right: 20px;
  }
  .border-bottom{
    border-bottom: 1px solid #f4f4f4;
  }
  .task_user_component{
    height: 50px;
  }
  .task_title_component{
    height: 70px;
  }
  .task_tag_component{
    height: 50px;
  }
  .widge_container_component{
    flex: 1;
    padding: 20px 20px 30px;
    position: relative;
  }
  .call_task_entrance{
    width: 100%;
    padding: 30px 0;
    border: 1px dashed #C6D0D6;
    border-radius: 4px;
    .text{
      font-size: 22px;
      font-weight: 400;
      color: #88A0B5;
      padding-top: 20px;
    }
  }
}
</style>