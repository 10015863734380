<template>
  <div 
    class="components_task_sidebar_teach_container_page bbox"
  >
    <page-start
      v-show="page_status=='start'"
      @handleChangePage="changePage"
      :can_teach="can_teach"
    ></page-start>
    <page-price
      v-show="page_status == 'price'"
      @handleChangePage="changePage"
      ref="page_price"
    ></page-price>
    <page-make
      v-show="page_status == 'make'"
      @handleChangePage="changePage"
      @handleSubmit="doSumbit"
      @handleSaveDraft="saveDraft"
      @handleSavaTask="handleSavaTask"
      :edit="edit"
      :use="use"
      :preview="preview"
      :maintain="maintain"
      :buy="buy"
      :sell="sell"
      :modify_mark="modify_mark"
      :show_intro="show_intro"
      :shopping_mail="shopping_mail"
      @handleReEdit="handleReEdit"
    ></page-make>
  </div>
</template>
<script>
/**
 * 教学查看状态
 * 
 * 编辑/预览/使用
 * 
 */
import PageStart from './Pages/Start.vue';
import PagePrice from './Pages/Price.vue';
import PageMake from './Pages/Make.vue'
import store from '@/store'
export default {
  components: {
    PageStart,
    PagePrice,
    PageMake,
  },
  props: {
    can_teach: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    show_intro: {
      type: Boolean,
      default: true,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      page_status: 'start',
      modify_mark: true, // 修改标记 // 当task_type == 2 时默认为false 其他为true
    }
  },
  computed: {
    edit(){
      const { modify_mark } = this
      const { task_config,task } = this.$store.state
      const { name, params } = this.$route
      const userid = this.$tools.getUserId()
      const { signUserId } = task.task
      const { task_type, draft, view, call, share } = task_config
      if (draft) {
        return true
      }
      if (share) {
        return false
      }
      if (call) {
        return false
      }
      if (!modify_mark) {
        return false
      }
      if (view) {
        if (name === 'fun_template_my' && params && params.type === 'sell' ) {
          return true
        }
        return false
      }
      // 自己是教学作者可以直接编辑
      // // console.log(task_type,signUserId , userid, draft )
      if (task_type == 2 ) {
        if (signUserId == userid) {
          return true
        }else{
          return false
        }
      }
      if (task_type == 3) {
        return false
      }
      return true
    },
    use(){
      const { task_config,task } = this.$store.state
      const userid = this.$tools.getUserId()
      const { signUserId, buy } = task.task
      const { task_type, teach, view, call } = task_config
      if (task_type == 2 ) {
        if (signUserId == userid) {
          return true
        } else {
          if (buy) {
            return true
          }
          return false
        }
      }
      // 有教学内容 并且购买可以使用
      if (teach && buy) {
        return true
      }
      // (实例 || 调用 || 预览) && 自己是作者
      if ((task_type == 3 || call || view) && signUserId == userid) {
        return true
      }
      return false
    },
    preview(){
      const {task_config,task} = this.$store.state;
      const userid = this.$tools.getUserId();
      const {signUserId, buy} = task.task;
      const {teach} = task_config;
      // 有教学内容 && 未购买 && 自己不是作者
      if(teach && !buy && signUserId != userid){
        return true;
      }
      return false;
    },
    // 维护
    maintain(){
      const {task_config,task} = this.$store.state;
      const {name, params} = this.$route
      // console.log(this.$route)
      const userid = this.$tools.getUserId();
      const {signUserId} = task.task;
      const {view, call, task_type, share} = task_config;
      if(signUserId == userid){
        if(share){
          return true;
        }
        if(call){
          return true
        }
        if(view){
          if(name === 'fun_template_my' && params && params.type === 'sell' ){
            return false
          }
          return true;
        }
        if(task_type == 3){
          return true;
        }
      }
      return false;
    },
    buy(){
      const {task_config,task} = this.$store.state;
      const userid = this.$tools.getUserId();
      const {signUserId, buy} = task.task;
      const {teach, price} = task_config;
      // 有教学内容 && 未购买 && 自己不是作者
      if(teach && !buy && signUserId != userid){
        if(Number(price) === 0){
          return true;
        }
      }
      return false;
    },
    

  },
  methods:{
    changePage(status){
      this.page_status = status;
      if(status == 'price'){
        this.$nextTick(function(){
          this.$refs.page_price.inputFocus();
        })
      }
    },
    doSumbit(){
      const {task_type, draft} = this.$store.state.task_config;
      // console.log(task_type);
      if(draft){
        // 教学提交审核将所有教学文件上传
        this.$api.moduleTask.createTeach({
          config: {
            call_teach: true,
          }
        });
        return;
      }
      if(task_type == 2){
        this.$api.moduleTask.editTeach();
        return;  
      }
      this.$api.moduleTask.createTeach();
    },
    saveDraft(){
      const {draft} = this.$store.state.task_config;
      if(draft){
        this.$api.moduleTask.editDraft();
      }else{
        this.$api.moduleTask.createDraft({config:{create_teach: true}});
      }
    },
    handleSavaTask(){
      this.$emit('handleSavaTask');
    },
    handleReEdit(val){
      this.$openConfirm({
        title:'提示',
        desc:'修改后需要重新提交审核,确定要修改教学内容?',
        sure:'确定',
        cancel:'取消',
        onSure: ()=> {
          this.modify_mark = val
          store.state.task_config.reEditTeach = true
        },
      })
      
    }

  },
  mounted(){
    const { task_config } = this.$store.state
    const { teach, task_type, share, call, draft } = task_config
    if (teach) {
      this.page_status = 'make'
    }
    if (task_type == 2 && !share && !call) {
      this.modify_mark = false
    }
    if (draft) {
      this.modify_mark = true
    }


  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_teach_container_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;

}
</style>