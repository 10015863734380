<template>
  <div
    class="components_task_side_bar_teach_pages_make_page bbox d-flex flex-column align-start"
  >
    <div class="main">
      <make-title
        @handleChangePage="changePage"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-title>
      <make-fun
        class="make_fun"
        title="简介"
        type="intro"
        :edit="edit"
        :use="use"
        :preview="preview"
        :open_add="false"
        v-if="show_intro"
      ></make-fun>
      <div
        v-if="show_intro"
        class="intro_group bbox pa-10"
      >
        <div
          class="intro"
        >
          {{ introduction }}
        </div>
      </div>
      <make-fun
        class="make_fun"
        title="视频"
        type="video"
        @handleClick="touchAdd"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-fun>
      <video-part
        class="part_group"
        ref="video"
        :list="videoList"
        :uploading="uploading"
        @handleAdd="addVideo"
        :edit="edit"
        :use="use"
        :preview="preview"
        @handleReUpload="reUpload"
      ></video-part>
      <make-fun
        class="make_fun"
        title="文件"
        type="file"
        @handleClick="touchAdd"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-fun>
      <file-part
        class="part_group"
        ref="file"
        :list="fileList"
        :uploading="uploading"
        @handleAdd="addFile"
        :edit="edit"
        :use="use"
        :preview="preview"
        @handleReUpload="reUpload"
      ></file-part>
      <make-fun
        class="make_fun"
        title="链接"
        type="hyperlink"
        @handleClick="touchAdd"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-fun>
      <hyperlink-part
        class="part_group"
        ref="hyperlink"
        :list="linkList"
        @handleAdd="addHyperlink"
        @handleEdit="editHyperlink"
        @handleDelete="deleteHyperlink"
        :edit="edit"
        :use="use"
        :sell="sell"
        :preview="preview"
      ></hyperlink-part>
    </div>
    <template
      v-if="!shopping_mail"
    >
      <div class="fun_group d-flex align-center justify-space-between flex-shrink-0"
        v-if="submit_status"
      >
        <div
          class="button btn_dark"
          :class="{
            big_button: !canSaveDraft
          }"
          @click="doSend();"
        >提交审核</div>
        <div
          v-if="canSaveDraft"
          class="button btn_light"
          @click="saveDraft();"
        >保存草稿</div>
      </div>
      <div class="fun_group"
        v-if="cunsulting_status"
      >
        <div class="button cunsulting fulid"
          @click="touchConsulting();"
        >咨询</div>
      </div>
      <div
        v-if="maintain_status"
        class="fun_group"
      >
        <div
          class="button btn_dark fulid"
          @click="doMaintain();"
        >
          前往维护
        </div>
      </div>
      <div class="fun_group"
        v-if="buy"
      >
        <div class="button btn_dark fulid"
          @click="doBuy()"
        >免费领取</div>
      </div>
      <div class="fun_group"
        v-if="modify_status"
      >
        <div class="button btn_dark fulid"
          @click="reEdit();"
        >重新编辑</div>
      </div>
    </template>

    <consulting
      v-if="switch_consulting"
      :param_id="Number(param_id)"
      :enquire_type="1"
      @handleClose="closeConsulting"
    ></consulting>
  </div>
</template>
<script>
import MakeTitle from './Make/Title.vue';
import MakeFun from './Make/Fun.vue';
import VideoPart from './Make/VideoPart.vue';
import FilePart from './Make/FilePart.vue';
import HyperlinkPart from './Make/HyperlinkPart.vue';
import Consulting from '@/components/PopUp/Consulting.vue';
import store from '@/store'
export default {
  components: {
    MakeTitle,
    MakeFun,
    VideoPart,
    FilePart,
    HyperlinkPart,
    Consulting,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    maintain: {
      type: Boolean,
      default: false, 
    },
    buy: {
      type: Boolean,
      default: false,
    },
    modify_mark: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    show_intro: {
      type: Boolean,
      default: true,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      uploading: false,
      switch_consulting: false,
    }
  },
  computed: {
    teach_files(){
      return this.$store.state.teach_files;
    },
    fileList(){
      const {teach_files} = this;
      if (!teach_files) {
        return []
      }
      const {fileList} = teach_files;
      return fileList;
    },
    linkList(){
      const {teach_files} = this;
      if (!teach_files){
        return []
      }
      const {linkList} = teach_files;
      return linkList;
    },
    videoList(){
      const {teach_files} = this;
      if (!teach_files) {
        return []
      }
      const {videoList} = teach_files;
      return videoList;
    },
    submit_status(){
      const { edit, modify_mark, sell } = this
      console.log({edit, modify_mark, sell})
      if (sell) {
        return false
      }
      return edit && modify_mark
    },
    canSaveDraft () {
      const { task } = this.$store.state
      const { draft, taskType } = task.task
      if (Number(taskType) === 2) {
        return Boolean(draft)
      }
      return true
    },
    maintain_status(){
      const { maintain, modify_mark, sell } = this
      const cookieUser = this.$tools.getCookie('user')
      console.log({cookieUser})
      if (cookieUser) {
        if (!cookieUser.sign) {
          return false
        }
      }
      if (sell) {
        return false
      }
      console.log(maintain, modify_mark, sell)
      return maintain && modify_mark ;
    },
    modify_status(){
      const {modify_mark, sell} = this;
      const {task_config,task} = this.$store.state;
      const userid = this.$tools.getUserId();
      const {signUserId} = task.task;
      const {name, params} = this.$route
      // console.log(this.$route)
      const {task_type, draft, view, call, share} = task_config;
      if(sell){
        return false;
      }
      // 自己是教学作者可以直接编辑
      // // console.log(task_type,signUserId , userid, draft )
      if(task_type == 2 ){
        if(signUserId == userid){
          if(!modify_mark){
            if(name === 'fun_template_my' && params && params.type === 'sell' ){
              return true
            }
          }
        }
      }
      return false;
    },
    cunsulting_status(){
      const {task_config,task} = this.$store.state;
      const {buy, sell} = this;
      const {task_type} = task_config;
      const {signUserId,price} = task.task;
      const userid = this.$tools.getUserId();
      // // console.log(buy);
      if(sell){
        return false;
      }
      if(task_type == 2 || task_type == 3){
        if(!buy && (price && price != 0) && !task.task.buy){
          if(signUserId != userid){
            return true;
          }
        }
      }
      return false;
    },
    param_id(){
      const {task} = this.$store.state;
      const {taskType, teachId, id} = task.task;
      if (taskType == 2) {
        return id;
      }
      return teachId;
    },
    introduction(){
      const {state} = this.$store;
      const {task_config} = state;
      const {introduction} = task_config;
      let intro = introduction ?? '';
      if(intro === ''){
        intro = '暂无简介';
      }
      return intro;
    }

  },
  mounted(){
    this.init();
  },
  methods: {
    changePage(status){
      this.$emit('handleChangePage', status)
    },
    touchAdd(type){
      this.$refs[type].touchAdd();
    },
    addVideo(list){
      // console.log(list);
      const {videoList, teach_files} = this;
      if(list.length){
        this.$store.commit('set_teach_files', Object.assign(
          {},
          teach_files,
          {videoList: videoList.concat(list)}
        ))
        this.$nextTick(function(){
          this.autoUpload('videoList');
        })

      }
    },
    addFile(list){
      const {fileList, teach_files} = this;
      if(list.length){
        this.$store.commit('set_teach_files', Object.assign(
          {},
          teach_files,
          {fileList: fileList.concat(list)}
        ))
        this.$nextTick(function(){
          this.autoUpload('fileList');
        })

      }
    },
    addHyperlink(data){
      const {linkList, teach_files} = this;
      this.$store.commit('set_teach_files', Object.assign(
        {},
        teach_files,
        {linkList: linkList.concat(data)}
      ))
    },
    editHyperlink(data){
      const {edit, item} = data;
      this.$set(item, 'content', edit.content);
      this.$set(item, 'edit', false);
      this.$set(item, 'modified', true);

    },
    deleteHyperlink(item){
      const {linkList} = this;
      const index = linkList.findIndex(unit=> {
        if(item.id != undefined){
          return unit.id == item.id;
        }
        if(item.custom_index != undefined){
          return item.custom_index == unit.custom_index;
        }
      })
      if(index != -1){
        linkList.splice(index, 1);
      }
    },
    // 自动查找第一个可上传文件上传
    async autoUpload(type){
      //标记控件修改
      this.uploading = true; // 正在上传
      this.$store.commit('set_task_config', Object.assign(
        {},
        this.$store.state.task_config,
        {[`uploading_teach_${type}`]:true}
      ))
      const list = this[type];
      const file = list.find(unit=>{
        return !unit.file.url && unit.file.status !== 'failed';
      })
      if(file !== undefined){
        let res = await this.upload(file.file);
        if(res){
          // this.$set(file, 'status', 'done');
          this.$set(file.file, 'url', res);
          this.$set(file.file, 'createTime', new Date().getTime());
          this.$set(file.file, 'status', '');
        }else{
          this.$set(file.file, 'status', 'failed');
        }
        this.autoUpload(type);
      }else{
        this.uploading = false; // 正在上传 结束
        this.$store.commit('set_task_config', Object.assign(
        {},
        this.$store.state.task_config,
        {[`uploading_teach_${type}`]:false}
      ))
        return;
      }

    },
    async reUpload(params){
      const {type, item} = params;
      const {file} = item;
      const {uploading} = this;
      if(uploading){
        this.$notice({desc: '有文件正在上传, 请稍候再试!'});
        return;
      }
      this.uploading = true; // 正在上传
      // this.$set(this.item, 'uploading', true); // 正在上传
      // const list = this[type];
      this.$set(file, 'status', '');
      let res = await this.upload(file);
      if(res){
        // this.$set(file, 'status', 'done');
        this.$set(file, 'url', res);
        this.$set(file, 'createTime', new Date().getTime());
        this.$set(file, 'status', '');
      }else{
        this.$set(file, 'status', 'failed');
      }
   
      this.uploading = false; // 正在上传 结束

    },
    async upload(file){
      // console.log(file);
      let res;
      const handleProgress = (progress)=>{
        this.$set(file, 'progress', Math.floor(progress*100));
      }
      res = await this.$api.oss.uploadFile({
        file: file.file,
        handleProgress
      })
      if(res !== false){
        return res;
      }
      return res;
    },
    doSend(){
      this.$emit('handleSubmit');
    },
    saveDraft(){
      this.$emit('handleSaveDraft');
    },
    async init(){
      console.log('init')
      const { $route } = this
      const { name } = $route
      let url = '/projectTeach/fileList'
      console.log(name)
      if (name === 'check_task'){
        url = '/projectOfficialWebsite/fileList'
      }
      const {task_config} = this.$store.state;
      const {teach, teach_id} = task_config;
      console.log(
        this.$store.state.task_config,
        this.$store.state.task
      )
      // console.log({teach, teach_id})
      // const {linkList, videoList, fileList} = this;
      if(teach_id && teach){
        const res = await this.$global.doPost({
          url,
          data: {teachId: teach_id},
          openNoitce: false,
        })
        if(res){
          this.$store.commit('set_teach_files', res.contents);
        }
      }
    },
     /** TODO 购买(模拟) */
    async doBuy(){
      let {task, task_config} = this.$store.state;
      let {price, teachId,teach_id, id, taskType} = task.task;
      let data_id;
      if(taskType == 3){
        data_id = teachId || teach_id;
      }
      if(taskType == 2){
        data_id = id;
      }
      let data = {
        id: data_id,
        price: price*100,
        type: 1,
      }
      let res = await this.$http.request('/projectTeach/buy',data);
      if(res.message == 'success'){
        // this.task_data.buy = true;
        // this.$set(this.task_data, 'buy', true);
        this.$store.commit('set_task', Object.assign(
          {},
          task,
          {
            task: {
              buy: true,
            }
          }
        ))
        // this.$toast({icon:'none',title:'提示',desc:'购买成功',duration:1000});
        this.$notice({desc: '购买成功', type: 'success'});
      }else{
        // this.$toast({icon:'none',title:'提示',desc:'购买失败',duration:1000});
        this.$notice({desc: '购买失败'});
      }
    },
    reEdit(){
      this.$emit('handleReEdit', true);
    },
    // 前往维护
    doMaintain(){
      // this.$store.commit('set_switch_task_box', false);
      // this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
      let moderated_task = false; 
      let {task, task_config} = this.$store.state;
      const {teachId} = task.task;
      const {task_id, task_type} = task_config;
      const {taskDeatails} = task;
      const id = task_type == 3 ? teachId : task_id;
      if(taskDeatails){
        taskDeatails.forEach(item=>{
          if(item.modified){
            moderated_task = true;
          }
          if(!moderated_task){
            if(item.controlLinkList && item.controlLinkList.length){
              item.controlLinkList.forEach(unit=> {
                if(unit.modified){
                  moderated_task  = true;
                }
              })
            }
          }
        })
      }
      // // console.log(moderated_task, task_details, '前往维护数据=')
      if(moderated_task){
        this.$openConfirm({
          title:'提示',
          desc:'当前模版内容有变更，跳转前是否发布模版？',
          sure:'发布',
          cancel:'不发布',
          onSure: async ()=> {
            this.$emit('handleSavaTask');
            this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
            this.$api.moduleTask.openTask({taskId:id ?? this.$store.state.task_original.task.id, config:{
              edit:true,
            }});
          },
          onCancel: () => {
            this.$api.moduleTask.closeTask();
            this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
            this.$api.moduleTask.openTask({
              taskId:id ?? this.$store.state.task_original.task.id ,
              config:{      
                edit:true,
              }
            });
          }
        })
      }else{
        this.$store.commit('set_task_config', Object.assign(
          {},
          this.$store.state.task_config,
          {
            open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
            open_with_call_page: false,
          }
        ))
        this.$api.moduleTask.closeTask();
        this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
        this.$api.moduleTask.openTask({taskId:id ?? this.$store.state.task_original.task.id, config:{
          edit:true,
          open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
          open_with_call_page: false,
        }});
        return;
      }
    },

    // 咨询
    touchConsulting(){
      const {state} = store
      const {task} = state
      if(task && task.task.cancelflag){
        this.$notice({desc: '作者已停止更新'})
        return
      }
      this.switch_consulting = true;
    },
    closeConsulting(){
      this.switch_consulting = false;
    }
  }
  
}
</script>
<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_page{
  width: 100%;
  height: 100%;
  overflow: auto;
  .main{
    @include scrollbar;
    overflow-y:auto;
    flex:1;
    width: 100%;
  }
  .make_fun{
    margin-top: 20px;
  }
  .part_group{
    padding-top: 10px;
  }
  .fun_group{
    width: 100%;
    padding-top: 10px;
    .button{
      box-sizing: border-box;
      font-size: 14px;
      line-height: 44px;
      border-radius: 4px;
      padding: 0 60px;
      text-align: center;
      &.big_button {
        flex: 1;
      }
      &.disabled{
        background-color: #CCCCCC;
      }
      &.fulid{
        width: 100%;
        text-align: center;
      }
      &.cunsulting{
        background-color: #2BB06C;
        color: #fff;
      }
    }
  }
  .intro_group{
    width: 100%;
    .intro{
      word-break: break-all;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
}
</style>