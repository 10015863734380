var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_pop_up_call_task_purchase_and_sell bbox" },
    [
      _vm.task_call_config.p_package
        ? _c(
            "div",
            {
              staticClass:
                "navigation_group d-flex align-center flex-wrap bbox",
            },
            [
              _c(
                "div",
                {
                  staticClass: "navigation cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doReturn()
                    },
                  },
                },
                [_vm._v("全部教学包")]
              ),
              _c("div", { staticClass: "navigation mx-6" }, [_vm._v(">")]),
              _c("div", { staticClass: "navigation active" }, [
                _vm._v(_vm._s(_vm.task_call_config.p_package.title)),
              ]),
            ]
          )
        : _vm._e(),
      _vm.show_package_list
        ? _c("package-list", {
            attrs: { task_call_config: _vm.task_call_config },
          })
        : _vm._e(),
      _vm.show_task_list
        ? _c("task-list", { attrs: { task_call_config: _vm.task_call_config } })
        : _vm._e(),
      _vm.show_package_detail
        ? _c("package-detail", {
            attrs: { task_call_config: _vm.task_call_config },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }